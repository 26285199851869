@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300..800;1,300..800&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto Flex', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h5 {
  font-family: 'Merriweather';
  color: #5B5B5B;
}

p,
ul,
ol,
dl,
address {
  font-size: 1.2rem;
  line-height: 1.5rem;
}

#me {
  border-radius: 5px;
}

.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 10px;
  transition: box-shadow 0.3s ease;
}

.card:hover {
  box-shadow: 0 8px 10px rgba(0, 0, 0.2, 0.2);
}

.card-title {
  font-family: 'Merriweather', serif;
  font-size: 1.5rem;
  margin-bottom: 8px;
}

.card-content {
  font-family: 'Roboto Flex', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5;
  color: #333;
}